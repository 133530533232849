<template>
<div style="margin-top:60px;">
    <navBar />
    <spinner/>
    <div id="layoutAuthentication">
        <div id="layoutAuthentication_content">
            <main>
                <div class="container-xl px-4">
                    <div class="row justify-content-center">
                        <div class="col-xl-5 col-lg-6 col-md-8 col-sm-11">
                            <div class="card my-5">
                                <div class="card-body p-5 text-center">
                                    <div class="h3 fw-light mb-3 titulo-login">Recuperar Contrase&ntilde;a</div>
                                </div>
                                <hr class="my-0" />
                                <div class="card-body p-5">
                                    <form>
                                        <div class="mb-3 text-left">
                                            <label class="text-gray-600 small " for="emailExample">Email</label>
                                            <input id="emailUser" class="form-control form-control-solid" type="text" placeholder="" aria-label="Email Address" aria-describedby="emailExample" />
                                        </div>
                                        <div class="d-flex align-items-center justify-content-center mb-0 pt-3">
                                            <a class="btn btn-primary color-btn" @click="recuperarPass">Recuperar</a>
                                        </div>
                                    </form>
                                </div>
                                <hr class="my-0" />
                                <div class="card-body px-5 py-4">
                                    <div class="small text-center">
                                        ¿Ya ten&eacute;s usuario?
                                        <a class="btn-link" @click="redirectLogin">¡Ingres&aacute;!</a>
                                    </div>
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
            </main>
        </div>

    </div>
</div>
</template>

<style>
/*Color del fondo, sobreescribiendo variable de del tema de boostrap SB ADMIN*/
:root {
    --bs-body-bg: #F5F5F5;
}

.titulo-login {
    font-size: 30px;
    color: rgb(0, 47, 135);
}

.color-btn {
    background: #002f87 !important;
    border: none;
}

.btn-link {
    color: #0061f2;
    cursor: pointer;
}
</style>

<script>
import navBar from "../components/landing/navBar";
import router from '@/router/index.js' // Vue router instance
import spinner from '../components/spinner'
import Vue from 'vue'

export default {
    name: "Home",
    components: {
        navBar,
        spinner,
    },
    mounted: function () {
        this.$store.commit("SET_BLOQUEADO", false);
    },
    methods: {
        recuperarPass() {
            let user = document.getElementById('emailUser').value;
            if(user != null && user.length>1){
                this.$store.dispatch("login/getRecuperarPass", user);
            }else{
                 Vue.swal({
                    title: "Proveedoras/es",
                    text: "El usuario es obligatorio",
                    icon: "info"
                })
            }
        },
        redirectLogin() {
            router.push("/login");
        },
    },
    computed: {},
};
</script>
